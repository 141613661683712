import request from '@/utils/request'

// 按分页获取所有系统列表
export function getSystemList(reqParams) {
  return request({
    url: '/userSystem/get/page',
    method: 'get',
    params: reqParams
  })
}

// 获取所有系统 /userSystem/get/role
export function getAllSystem() {
  return request({
    url: '/userSystem/get/role',
    method: 'get'
  })
}

// 新增系统
export function addSystem(reqParams) {
  return request({
    url: '/userSystem/add',
    method: 'post',
    data: reqParams
  })
}

// 删除系统
export function deleteSystem(id) {
  return request({
    url: `/userSystem/delete/${id}`,
    method: 'delete'
  })
}

// 编辑系统
export function editSystem(id) {
  return request({
    url: `/userSystem/get/${id}`,
    method: 'get'
  })
}

// 保存编辑
export function updateSystem(reqParams) {
  return request({
    url: `/userSystem/update`,
    method: 'put',
    data: reqParams
  })
}

// function getUserIP(onNewIP) { //  onNewIp - your listener function for new IPs
//   //compatibility for firefox and chrome
//   var myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
//   var pc = new myPeerConnection({
//       iceServers: []
//     }),
//     noop = function () {},
//     localIPs = {},
//     ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
//     key;

//   function iterateIP(ip) {
//     if (!localIPs[ip]) onNewIP(ip);
//     localIPs[ip] = true;
//   }
//   //create a bogus data channel
//   pc.createDataChannel("");
//   // create offer and set local description
//   pc.createOffer().then(function (sdp) {
//     sdp.sdp.split('\n').forEach(function (line) {
//       if (line.indexOf('candidate') < 0) return;
//       line.match(ipRegex).forEach(iterateIP);
//     });
//     pc.setLocalDescription(sdp, noop, noop);
//   }).catch(function (reason) {
//     // An error occurred, so handle the failure to connect
//   });
//   //sten for candidate events
//   pc.onicecandidate = function (ice) {
//     if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
//     ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
//   };
// }
// // Usage
// getUserIP(function (ip) {
//   alert("Got IP! :" + ip);
// });
